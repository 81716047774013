<script setup>
import {
    onMounted,
    ref
} from 'vue';

import {
    closeIcon
} from '../icons';

const emits = defineEmits(["showPopup"]);

const closePop = () => {
    localStorage.setItem("isShowPopup", "false");
    emits("showPopup");
    isShowPopup.value = !isShowPopup.value;
}

const goToLink = () => {
    location.href = "/survey/PhilSMESurvey";
    localStorage.setItem("isShowPopup", "false");
    emits("showPopup");
}

const isShowPopup = ref(true);
</script>
<template>
    <Teleport to="#outside">
        <div v-if="isShowPopup" class="fixed z-[999999999] flex justify-center items-start md:pt-[10rem] pt-[6rem] bg-black/20 h-full w-full px-3">
            <div class="relative flex justify-center flex-col items-center bg-white rounded-xl w-[500px]">
                <closeIcon  @click="closePop()" class="absolute top-2 right-2 w-[15px] md:w-[30px] cursor-pointer"></closeIcon>
                <img class="w-full" :src="'/images/popup/PhilSME Exclusive Pop-Up Banner.webp'" alt="">
                <div class="">
                    <button @click="goToLink()" class="bg-smicos-secondary px-5 py-3 text-[12px] md:text-[15px] md:px-10 md:py-5 rounded-full text-white my-5">Register for FREE</button>
                </div>
            </div>
        </div>
    </Teleport>
</template>
