<script setup>
import { reactive, ref, nextTick } from 'vue';
import { inputOptions } from "@/data/registration.js";
const inputs = inputOptions();
import { validateForm } from '@/validation';
import loading from '@/components/includes/loading.vue';

const finds = [
    {
        title: "Facebook"
    },
    {
        title: "Instagram"
    },
    {
        title: "TikTok"
    },
    {
        title: "LinkedIn"
    },
    {
        title: "X (Formerly Twitter)"
    },
    {
        title: "Youtube"
    },
    {
        title: "Website"
    },
];

const interactions = [
    {
        title: "Primarily face-to-face",
    },
    {
        title: "Via phone calls",
    },
    {
        title: "Through email",
    },
    {
        title: "Social media and messaging platforms (e.g., Facebook, Instagram, WhatsApp)",
    }
];

const manages = [
    {
        title: "High volume of inquiries"
    },
    {
        title: "Delays in responding"
    },
    {
        title: "Difficulty tracking customer concerns"
    },
    {
        title: "Lack of tools for seamless communication"
    }
];

const platforms_business_operations = [
    {
        title: "Yes, we are using automation tools."
    },
    {
        title: "Yes, but we are still exploring options."
    },
    {
        title: "No, we are not currently considering automation."
    },
    {
        title: "No, but we are curious to learn more."
    },
];

const features_chatbot_business = [
    {
        title: "Answering FAQs"
    },
    {
        title: "Managing bookings or appointments"
    },
    {
        title: "Providing 24/7 customer support"
    },
    {
        title: "Processing orders or inquiries"
    },
    {
        title: "Integrating with current systems (e.g., website, social media)"
    }
];

const descisions_adapt_chatbot = [
    {
        title: "Affordability"
    },
    {
        title: "Ease of setup and use"
    },
    {
        title: "Ability to personalize interactions"
    },
    {
        title: "Integration with existing tools"
    },
    {
        title: "Customer feedback or demand"
    }
];

const chatbot_ranks = [
    {
        title: "High priority"
    },
    {
        title: "Medium priority"
    },
    {
        title: "Low priority"
    },
    {
        title: "Not a priority"
    },
];

const chatbot_advantages = [
    {
        title: "Yes, many are"
    },
    {
        title: "Some are"
    },
    {
        title: "No, few or none are"
    },
    {
        title: "Not sure"
    },
];

const explorings = [
    {
        title: "Yes, absolutely!"
    },
    {
        title: "Maybe, I’d need more details."
    },
    {
        title: "No, I’m not interested."
    }
];

const company_sizes = [
    {
        title: "1-10 employees"
    },
    {
        title: "11-50 employees"
    },
    {
        title: "51-200 employees"
    },
    {
        title: "201-500 employees"
    },
    {
        title: "501-1000 employees"
    },
    {
        title: "1001-5000 employees"
    },
    {
        title: "5001-10000 employees"
    },
    {
        title: "10001+ employees"
    },
]

const errors = ref([]);

const platforms = ref([]);
const priority = ref([]);
const company_nature_of_business = ref("");
const other_interactions = ref("");
const other_manages = ref("");
const other_feature_chatbot_business = ref("");
const other_descision_adapt_chatbot = ref("");

const data = reactive({
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    company_name: "",
    company_position: "",
    company_size: "",
    company_nature_of_business: "",
    platforms: [],
    priority: [],
    content: {
        platforms: [],
        priority: [],
        company_nature_of_business: "",
        interactions: [],
        manages: [],
        platforms_business_operations: [],
        feature_chatbot_business: [],
        descision_adapt_chatbot: [],
        chatbot_advantage: [],
        exploring: [],

        other_interactions: "",
        other_manages: "",
        other_feature_chatbot_business: "",
        other_descision_adapt_chatbot: ""
    }
});

const rules = [
    {
        name : "First Name",
        field : "first_name",
        required : true,
    },
    {
        name: "Last Name",
        field: "last_name",
        required: true,
    },
    {
        name: "Email",
        field: "email",
        email: true,
        required: true,
    },
    {
        name: "Contact Number",
        field: "contact_number",
        required: true,
    },
    {
        name: "Company Name",
        field: "company_name",
        required: true,
    },
    {
        name: "Company Position",
        field: "company_position",
        required: true,
    },
    {
        name: "Company Size",
        field: "company_size",
        required: true,
    },
    {
        name: "Nature of Business",
        field: "company_nature_of_business",
        required: true,
    }
];

const rules_2 = [
    {
        name : "First Name",
        field : "first_name",
        required : true,
    },
    {
        name: "Last Name",
        field: "last_name",
        required: true,
    },
    {
        name: "Email",
        field: "email",
        email: true,
        required: true,
    },
    {
        name: "Contact Number",
        field: "contact_number",
        required: true,
    },
    {
        name: "Company Name",
        field: "company_name",
        required: true,
    },
    {
        name: "Company Position",
        field: "company_position",
        required: true,
    },
    {
        name: "Company Size",
        field: "company_size",
        required: true,
    },
    {
        name: "Nature of Business",
        field: "company_nature_of_business",
        required: true,
    },
    {
        name: "Platforms",
        field: "platforms",
        required: true,
    },
    {
        name: "Priority",
        field: "priority",
        required: true,
    },
    {
        name: "Interactions",
        field: data.content.interactions,
        required: true,
    },
    {
        name: "Manages",
        field: data.content.manages,
        required: true,
    },
    {
        name: "Platforms Business Operations",
        field: data.content.platforms_business_operations,
        required: true,
    },
    {
        name: "Feature Chatbot Business",
        field: data.content.feature_chatbot_business,
        required: true,
    },
    {
        name: "Decision Adapt Chatbot",
        field: data.content.descision_adapt_chatbot,
        required: true,
    },
    {
        name: "Chatbot Advantage",
        field: data.content.chatbot_advantage,
        required: true,
    },
    {
        name: "Exploring",
        field: data.content.exploring,
        required: true,
    }
];

const form1$ = validateForm(rules,data);
const v$ = validateForm(rules_2,data);
const onLoading = ref(false);

const sendData = () => {
    onLoading.value = true;
    try {
        axios.post('https://app.smicos.com/api/website/sme-survey',data,{
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {
            location.href = '/survey/PhilSMESurvey/thank-you';
            onLoading.value = false;
        })
        .catch(error => {
            errors.value = error.response.data.errors.email[0];
            nextTick(() => { // Use nextTick
                const element = document.querySelector('#heatmark');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            });
            onLoading.value = false;
        });
    }catch (e) {
        console.log(e.message);
        onLoading.value = false;
    }
}

const changePlatforms = () => {
    data.platforms = platforms.value;
    data.content.platforms = platforms.value;
}

const changePriority = () => {
    data.priority = priority.value;
    data.content.priority = priority.value;
}

const changeCompanyNatureOfBusiness = () => {
    data.company_nature_of_business = company_nature_of_business.value;
    data.content.company_nature_of_business = company_nature_of_business.value;
}

</script>
<template>
<!-- SURVEY FORM (SME Chatbot Implementation Survey) -->
 <!-- LOADING -->
<loading v-if="onLoading"></loading>
<!-- LOADING -->
<div class="pt-[7.5rem] flex flex-col mx-auto max-w-[1250px] px-5">
    <div class="flex justify-center flex-col items-center">
        <img class="rounded-xl" :src="`/images/survey/sme_survey_banner.webp`" alt="">
        <br>
        <h1 class="font-extrabold md:text-[40px] text-[34px] bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text text-transparent">
            SME Chatbot Implementation Survey
        </h1>
        <br>
        <span class="text-center">
            This survey aims to understand Small and Medium-sized Enterprises' (SMEs) Digital Integration and perception of Chatbot Implementation to business operations. The survey should take only a few minutes to finish, and your feedback will be instrumental to shaping more solutions tailored to SMEs. Thank you for your participation!
        </span>
        <br>
        <h2 id="heatmark" class="font-semibold md:text-[30px] text-[24px] bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text text-transparent">
            Data Privacy Policy Statement
        </h2>
        <br>
        <span>
            We value your privacy and are committed to protecting your personal information. By participating in the Smicos Survey, you consent to the collection and processing of your responses exclusively for research purposes, aimed at better understanding the digital integration and chatbot needs of Small and Medium-sized Enterprises (SMEs). By participating, you also agree to
            <a target="_blank" href="/terms-condition-privacy-policy" class="underline text-[#0097A7]">Smicos' Terms and Conditions and Privacy Policy.</a>
        </span>
        <br>
        <span>
            By submitting this survey, you also consent to the creation of a Smicos account, providing access to our platform.
            You may opt out or request data deletion at any time by contacting our support team.
        </span>
    </div>
    <div class="mt-10 flex flex-col gap-10">
        <div id="contents" class="transition-all bg-[#F2DEDE] text-red-500 p-5 rounded-md font-semibold text-[14px] flex flex-col" v-if="errors.length > 0">
            <span class="font-bold">The following error(s) must be corrected.</span>
            <ol class="flex gap-2">
                <li>{{ errors }}</li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <div class="flex gap-5">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        First Name <span class="text-red-500">*</span>
                    </span>
                    <input
                        v-model="data.first_name"
                        type="text"
                        class="border w-full rounded-md p-2 focus:outline-smicos-primary "
                        placeholder="Juan"
                    />
                </div>
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        Last Name <span class="text-red-500">*</span>
                    </span>
                    <input
                        v-model="data.last_name"
                        type="text"
                        class="border w-full rounded-md p-2 focus:outline-smicos-primary "
                        placeholder="Dela Cruz"
                    />
                </div>
            </div>
            <div class="flex gap-5">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        Email <span class="text-red-500">*</span>
                    </span>
                    <input
                        v-model="data.email"
                        type="email"
                        class="border w-full rounded-md p-2 focus:outline-smicos-primary "
                        placeholder="juan.delacruz@email.com"
                    />
                </div>
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        Contact Number <span class="text-red-500">*</span>
                    </span>
                    <input
                        v-model="data.contact_number"
                        type="text"
                        class="border w-full rounded-md p-2 focus:outline-smicos-primary "
                        placeholder="09123 123 123"
                    />
                </div>
            </div>
            <div class="flex gap-5">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        Company Name <span class="text-red-500">*</span>
                    </span>
                    <input
                        v-model="data.company_name"
                        type="text"
                        class="border w-full rounded-md p-2 focus:outline-smicos-primary "
                    />
                </div>
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        Company Position <span class="text-red-500">*</span>
                    </span>
                    <input
                        v-model="data.company_position"
                        type="text"
                        class="border w-full rounded-md p-2 focus:outline-smicos-primary "
                    />
                </div>
            </div>
            <div class="flex gap-5">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        Size of the Company <span class="text-red-500">*</span>
                    </span>
                    <div class="border rounded-md p-2 cursor-pointer w-full">
                        <select
                            class="w-full outline-none cursor-pointer"
                            v-model="data.company_size"
                        >
                            <option
                                v-for="company_size in company_sizes"
                                :value="company_size.title"
                                :key="company_size"
                                class="bg-white"
                            >
                                {{ company_size.title }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        Nature of business <span class="text-red-500">*</span>
                    </span>
                    <div class="border rounded-md p-2 cursor-pointer w-full">
                        <select
                            class="w-full outline-none cursor-pointer"
                            v-model="company_nature_of_business"
                            @change="changeCompanyNatureOfBusiness()"
                        >
                            <option
                                v-for="industry, index in inputs.industries"
                                :value="industry"
                                :key="index"
                                class="bg-white"
                            >
                                {{ industry }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!form1$.$invalid" class="flex flex-col gap-10">
            <div class="flex gap-5">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        Which Platform/s does your business actively use? (Check all that applies.) <span class="text-red-500">*</span>
                    </span>
                    <div class="flex flex-wrap gap-5">
                        <div class="flex items-center gap-2" v-for="find in finds" :key="find">
                            <input type="checkbox" :id="find.title" :value="find.title" v-model="platforms" @change="changePlatforms()">
                            <label :for="find.title">{{ find.title }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex gap-5">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        What is your current approach to customer interactions? <span class="text-red-500">*</span>
                    </span>
                    <div class="flex flex-wrap gap-5">
                        <div class="flex items-center gap-2" v-for="interaction in interactions" :key="interaction">
                            <input v-model="data.content.interactions" :value="interaction.title" :id="interaction.title" type="radio" name="interactions">
                            <label :for="interaction.title">{{ interaction.title }}</label>
                        </div>
                        <div class="flex items-center gap-2">
                            <input v-model="other_interactions" id="other_interactions" type="radio" name="interactions">
                            <label for="other_interactions">Other (please specify):</label>
                            <input
                                class="border w-full rounded-md p-2 focus:outline-smicos-primary "
                                v-if="other_interactions != ''"
                                type="text"
                                v-model="data.content.other_interactions"

                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex gap-5">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        What challenges do you face in managing customer inquiries or interactions? (Select all that apply) <span class="text-red-500">*</span>
                    </span>
                    <div class="flex flex-wrap gap-5">
                        <div class="flex items-center gap-2" v-for="manage in manages" :key="manage">
                            <input v-model="data.content.manages" :value="manage.title" type="checkbox" :id="manage.title">
                            <label :for="manage.title">{{ manage.title }}</label>
                        </div>
                        <div class="flex items-center gap-2">
                            <input v-model="other_manages" id="other_manages" type="checkbox" name="manages">
                            <label for="other_manages">Other (please specify):</label>
                            <input
                                class="border w-full rounded-md p-2 focus:outline-smicos-primary "
                                v-if="other_manages != ''"
                                type="text"
                                v-model="data.content.other_manages"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex gap-5">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        Have you explored any tools or platforms to automate parts of your business operations? <span class="text-red-500">*</span>
                    </span>
                    <div class="flex flex-wrap gap-5">
                        <div class="flex items-center gap-2" v-for="platforms_business_operation in platforms_business_operations" :key="platforms_business_operation">
                            <input v-model="data.content.platforms_business_operations" :value="platforms_business_operation.title" :id="platforms_business_operation.title" type="radio" name="platforms_business_operations">
                            <label :for="platforms_business_operation.title">{{ platforms_business_operation.title }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex gap-5">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        What features would you find most valuable in a chatbot for your business? (Select all that apply) <span class="text-red-500">*</span>
                    </span>
                    <div class="flex flex-wrap gap-5">
                        <div class="flex items-center gap-2" v-for="feature_chatbot_business in features_chatbot_business" :key="feature_chatbot_business">
                            <input v-model="data.content.feature_chatbot_business" :value="feature_chatbot_business.title" type="checkbox" :id="feature_chatbot_business.title">
                            <label :for="feature_chatbot_business.title">{{ feature_chatbot_business.title }}</label>
                        </div>
                        <div class="flex items-center gap-2">
                            <input v-model="other_feature_chatbot_business" id="other_feature_chatbot_business" type="checkbox" name="feature_chatbot_business">
                            <label for="other_feature_chatbot_business">Other (please specify):</label>
                            <input
                                class="border w-full rounded-md p-2 focus:outline-smicos-primary "
                                v-if="other_feature_chatbot_business != ''"
                                type="text"
                                v-model="data.content.other_feature_chatbot_business"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex gap-5">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        What factors would influence your decision to adopt a chatbot solution for your business? (Select all that apply) <span class="text-red-500">*</span>
                    </span>
                    <div class="flex flex-wrap gap-5">
                        <div class="flex items-center gap-2" v-for="descision_adapt_chatbot in descisions_adapt_chatbot" :key="descision_adapt_chatbot">
                            <input v-model="data.content.descision_adapt_chatbot" type="checkbox" :value="descision_adapt_chatbot.title" :id="descision_adapt_chatbot.title">
                            <label :for="descision_adapt_chatbot.title">{{ descision_adapt_chatbot.title }}</label>
                        </div>
                        <div class="flex items-center gap-2">
                            <input v-model="other_descision_adapt_chatbot" id="other_descision_adapt_chatbot" type="checkbox" name="descision_adapt_chatbot">
                            <label for="other_descision_adapt_chatbot">Other (please specify):</label>
                            <input
                                class="border w-full rounded-md p-2 focus:outline-smicos-primary "
                                v-if="other_descision_adapt_chatbot != ''"
                                type="text"
                                v-model="data.content.other_descision_adapt_chatbot"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex gap-5">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        Where does implementing a chatbot rank among your business’s digital transformation priorities? <span class="text-red-500">*</span>
                    </span>
                    <div class="flex flex-wrap gap-5">
                        <div class="flex items-center gap-2" v-for="chatbot_rank in chatbot_ranks" :key="chatbot_rank">
                            <input v-model="priority" :value="chatbot_rank.title" :id="chatbot_rank.title" type="radio" name="chatbot_ranks" @change="changePriority()">
                            <label :for="chatbot_rank.title">{{ chatbot_rank.title }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex gap-5">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        Do you think your competitors are using chatbots to their advantage? <span class="text-red-500">*</span>
                    </span>
                    <div class="flex flex-wrap gap-5">
                        <div class="flex items-center gap-2" v-for="chatbot_advantage in chatbot_advantages" :key="chatbot_advantage">
                            <input v-model="data.content.chatbot_advantage" :value="chatbot_advantage.title" :id="chatbot_advantage.title" type="radio" name="chatbot_advantages">
                            <label :for="chatbot_advantage.title">{{ chatbot_advantage.title }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex gap-5">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-lg">
                        If a solution existed that provided a free access of a chatbot service tailored for SMEs, could help you handle customer inquiries 24/7, and save time and resources, would you consider exploring it? <span class="text-red-500">*</span>
                    </span>
                    <div class="flex flex-wrap gap-5">
                        <div class="flex items-center gap-2" v-for="exploring in explorings" :key="exploring">
                            <input v-model="data.content.exploring" :value="exploring.title" :id="exploring.title" type="radio" name="explorings">
                            <label :for="exploring.title">{{ exploring.title }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button :disabled="v$.$invalid" :class="v$.$invalid ? 'opacity-30' : ''" class="bg-smicos-primary hover:shadow-lg transition-all px-5 mt-10 py-3 rounded-full uppercase font-bold text-[15px] text-white" @click="sendData">Submit</button>
</div>
</template>
