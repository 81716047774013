import useVuelidate from '@vuelidate/core'
import { helpers, required, minLength, maxLength, numeric, alpha, alphaNum, email } from '@vuelidate/validators'

const hasFile = (value) => {
    return !!value; // Returns true if a file is present
};

export function validateForm(rules,data) {
    const validationFields = {};

    for (const rule of rules) {

      const field = rule.field;
      validationFields[field] = {};

      if (rule.required) {
        validationFields[field].required = helpers.withMessage(rule.name+' field is required', required);
      }

      if (rule.min) {
        validationFields[field].minLength = helpers.withMessage(rule.name+' field must be greater than '+rule.min+' characters', minLength(rule.min));
      }

      if (rule.max) {
        validationFields[field].maxLength = helpers.withMessage(rule.name+' field must be less than '+rule.max+' character/s', maxLength(rule.max));
      }

      if (rule.numeric) {
        validationFields[field].numeric = helpers.withMessage(rule.name+' field must be a number', numeric);
      }

      if (rule.alpha) {
        validationFields[field].alpha = helpers.withMessage(rule.name+' field must be an alpha', alpha);
      }

      if (rule.alphaNum) {
        validationFields[field].alphaNum = helpers.withMessage(rule.name+' field must be an alpha numeric', alphaNum);
      }

      if (rule.email) {
        validationFields[field].email = helpers.withMessage(rule.name+' field must be an email', email);
      }

      if (rule.hasFile) {
        validationFields[field].hasFile = helpers.withMessage(rule.name+' is required', hasFile);
      }
    }

    return useVuelidate(validationFields, data);
}
